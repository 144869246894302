// import Cryptr from 'cryptr';
import errorHandler from "@/services/handler/erroHandler";
import axiosInstance from "@/services/manager/manager.service";
import {cryptUtil} from "@/utils/crypt.util";

export const authService = {
    login,
    saveToken,
    getToken,
    getRefreshToken,
    deleteToken,
    updateInformation,
    updatePassword,
    forgotPassword,
    resetPassword,
    localUserInfo,
    logOut,
    isLogged,
    setRemenberMe,
    getRememberME,
    deleteRememberMe,
    getAuthenticatedUser
};
/*let crypt = new Cryptr(process.env.VUE_APP_REMEMBER_CRYPT);*/

async function login(login) {

    try {
        let rets = await axiosInstance.post(`/login`, login);
        if (rets) {
            saveToken(rets.data.token, rets.data.refreshToken);
            return rets;
        }
        return 400;
    } catch (error) {
        return errorHandler(error);
    }
}

function saveToken(token, refresh) {
    sessionStorage.setItem('accessToken', token);
    sessionStorage.setItem('refreshToken', refresh);
}


function getToken() {
    return sessionStorage.getItem('accessToken');
}

function getRefreshToken() {
    return sessionStorage.getItem('refreshToken')
}

function deleteToken() {
    sessionStorage.removeItem('accessToken');
    sessionStorage.removeItem('refreshToken');
    sessionStorage.removeItem('GPUI');
}

function logOut() {
    deleteToken();
    window.location.href = '/login';
}

function isLogged() {
    return !! getToken();
}

function setRemenberMe(username, password, remember) {
    const data = {
        /*'username': crypt.encrypt(username),
        'password': crypt.encrypt(password),*/
        'remember': remember
    }

    localStorage.setItem('remember', JSON.stringify(data))
}

function getRememberME() {
    let data = localStorage.getItem('remember');
    if (data != null) {
        data = JSON.parse(data);
        /*data.username = crypt.decrypt(data.username);
        data.password = crypt.decrypt(data.password);*/
        return data;
    }
    return null;
}

async function updateInformation(userId, user) {
    try {
        let ret = await axiosInstance.put(`/auth/updateAccount/${userId}`, user);
        return ret.data;
    } catch (error) {
        return errorHandler(error);
    }
}

async function updatePassword(userId, oldPassword, newPassword) {
    try {
        let ret = await axiosInstance.put(`/gozik/users/${userId}/password`, {'password': oldPassword, 'newPassword': newPassword});
        return ret.data;
    } catch (error) {
        return errorHandler(error);
    }
}

async function forgotPassword(email) {
    try {
        let ret = await axiosInstance.post(`/gozik/users/regen_pass`, email);
        return ret.data;
    } catch (error) {
        return errorHandler(error);
    }
}

async function resetPassword(reGenCode, newPassword) {
    try {
        let ret = await axiosInstance.put(`/gozik/users/${reGenCode}/pass`, {'password': newPassword});
        return ret.data;
    } catch (error) {
        return errorHandler(error);
    }
}

function deleteRememberMe() {
    localStorage.removeItem('remember');
}

function getAuthenticatedUser() {
    const sessionUser = cryptUtil.decrypt(sessionStorage.getItem('GPUI'), 'gp-encrypt');
    return  JSON.parse(sessionUser);
}

function localUserInfo() {
    const sessionUser = cryptUtil.decrypt(sessionStorage.getItem('GPUI'), 'gp-encrypt');
    return  JSON.parse(sessionUser);
}



import router from "../../router";

export default function errorHandler(error) {
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        if (error.response.status == 401 || error.response.status == 403) {
           return router.push('/login');
        } 
        return error.response.status;
    } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        //console.log(error.request);
        return 500;
    } else {
        // Something happened in setting up the request that triggered an Error
        return 500;
    }
}
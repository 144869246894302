import {AES, enc} from 'crypto-js'

export const cryptUtil = {
    encrypt,
    decrypt
}

// data encryption
function encrypt(data, password) {
    return AES.encrypt(data, password).toString()
}

// data decryption
function decrypt (ciphertext, password) {
    const bytes = AES.decrypt(ciphertext, password)
    return bytes.toString(enc.Utf8)
}
